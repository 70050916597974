import React from 'react';
import Header from '../components/Header';
import Layout from "../components/layout";

const NotFoundPage = () => (  
  <Layout>
    <Header/>
    <h1>NOT FOUND</h1>
    <h3>The page may have been moved, updated or deleted.</h3>
      <p>
        Or you may have typed the web address incorrectly. Please check the
        address and spelling, or use our navigation menu at the top.
      </p>
  </Layout>
)

export default NotFoundPage
