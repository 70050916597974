import React, {Component} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import logo from '../../assets/ventureweb_logo.png';
import Nav from '../Nav';


class Header extends Component {
//const Header = props => (
 

  render() {
    return ( 
      <Headerbar>
        <Logo id="logo">
          <Link to="/">
            <img src={logo} alt=""/>
          </Link>
        </Logo>
        <Nav/>    
      </Headerbar>
    );
  }
}
export default Header;

const Headerbar = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  z-index: 2;
  .mainMenu {
    flex: 0 0 auto;
    max-width: none;
    align-self: flex-end;
  }
`

const Logo = styled.div`
  display: inline-block;
  padding-right: 1rem;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  a {
    display: block;
  }
  img {
    margin: 0;
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 1024px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  @media (max-width: 991px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
  @media (max-width: 480px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
`